import React, {useState, useEffect, useMemo, Suspense, useRef} from "react";
import {Link} from "react-router-dom";
import "../../css/Home.css";
import Food1 from "../../assets/banners/food1.webp";
import Food2 from "../../assets/banners/food2.webp";
import Food3 from "../../assets/banners/food3.webp";
import Food5 from "../../assets/banners/food5.webp";
import Food1Low from "../../assets/banners/food1mobile.webp";
import Food2Low from "../../assets/banners/food2mobile.webp";
import Food3Low from "../../assets/banners/food3mobile.webp";
import Food5Low from "../../assets/banners/food5mobile.webp";
import Footer from "../Footer/Footer";
import Logo from "../../assets/logos/Restohub-Logo.webp";
import FindRestaurant from "../location/FindRestaurant";
import RestaurantCarousalSkeleton from "../skeletonLoaders/RestaurantCarousalSkeleton";
import SearchBarSkeleton from "../skeletonLoaders/SearchBarSkeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {axiosInstance} from "../../util/axiosInstance";
import {endPoints} from "../../util/endPoints";
import {loadGoogleMaps} from "../Maps/GoogleMapLoader";
import ReactGA from "react-ga4";
import {Restaurants} from "../../interfaces/Restaurant";
import {Category} from "../../interfaces/Category";
import {Utility} from "../../interfaces/Utility";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {Offer} from "../../interfaces/Offers";
import {useOffer} from "../../reducers/ui/offerHook";
import {useRestaurant} from "../../reducers/ui/restaurantHook";
import MobileRestaurantCard from "../Restaurants/MobileRestaurantCard";
import {useLocationName} from "../../reducers/ui/locationNameHoook";
import MobileCardSkeleton from "../skeletonLoaders/MobileCardSkeleton";
import {Autocomplete} from "@react-google-maps/api";
import {useLocation} from "../../reducers/ui/locationHook";

const OfferSkeleton = React.lazy(
    () => import("../skeletonLoaders/OfferSkeleton")
);
const OfferALt = React.lazy(() => import("../Offer/OfferAlt"));
const OfferRes = React.lazy(() => import("../Offer/OfferRes"));
const NearByRestaurant = React.lazy(
    () => import("../location/NearByRestaurant")
);


function Home() {
    let [liveLocation, setLocation] = useLocationName();
    const [restaurantBranches, setRestaurantBranches] = useState<Restaurants[]>(
        []
    );
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [googleMapsApiKey, setGoogleMapsApiKey] = useState<string>("");
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const libraries = useMemo(() => ["places"], []);
    const [option, setOption] = useState<string>("Walk & Pick");
    const [categories, setCategories] = useState<Category[]>([]);
    const [category, setCategory] = useState<string>("");
    const [utility, setUtility] = useState<Utility>();
    const containerRef = useRef<HTMLDivElement>(null);
    const [isScrollable, setIsScrollable] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const locationRef = useRef<HTMLInputElement>(null);
    let [configLocation, setConfigLocation] = useLocation();


    const handleScroll = (scrollOffset: number) => {
        if (containerRef.current) {
            containerRef.current.scrollLeft += scrollOffset;
        }
    };

    // const state = useSelector((state) => state);
    // const dispatch = useDispatch();
    // console.log("store", state);
    let [, setRestaurants] = useRestaurant();
    let [, setOffers] = useOffer();

    const getCurrentSriLankanTime = () => {
        const ISTOffset = 330; // Sri Lanka is GMT+5:30
        const currentDate = new Date();
        console.log("SrilankanTime", currentDate)
        console.log("SrilankanTime", currentDate.getHours())
        return currentDate.getHours();
    };

    const getTimeCategory = () => {
        const currentHour = getCurrentSriLankanTime();
        const lunchTimeStart = utility ? utility.lunchTimeStart : 12;
        const dinnerTimeStart = utility ? utility.dinnerTimeStart : 18;

        if (currentHour >= lunchTimeStart && currentHour < dinnerTimeStart) {
            return "Lunch";
        } else if (currentHour >= dinnerTimeStart) {
            return "Dinner";
        } else {
            return "Sri Lankan";
        }
    };


    const handleChange = () => {
        if (locationRef.current) {
            setLocation(locationRef.current.value);
        }
    };

    useEffect(() => {
        if (locationRef.current) {
            locationRef.current.addEventListener("input", handleChange);
            const autocomplete = new window.google.maps.places.Autocomplete(locationRef.current);

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (place && place.formatted_address) {
                    setLocation(place.formatted_address);
                    if (place.geometry && place.geometry.location) {
                        const lat = place.geometry.location.lat();
                        const lng = place.geometry.location.lng();
                        const userLocation = {lat, lng};
                        setConfigLocation(userLocation);
                        console.log("Latitude:", lat);
                        console.log("Longitude:", lng);
                    } else {
                        console.error("Geometry or location is undefined for the selected place.");
                    }
                }
            });
        }

        return () => {
            if (locationRef.current) {
                locationRef.current.removeEventListener("input", handleChange);
            }
        };
    }, [locationRef.current]);


    useEffect(() => {
        if (locationRef.current && liveLocation !== null && liveLocation !== undefined) {
            locationRef.current.value = liveLocation.toString();
        }
    }, [liveLocation]);

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setIsScrollable(containerRef.current.scrollWidth > containerRef.current.clientWidth - 250);
            }
        };

        const handleScroll = () => {
            if (containerRef.current) {
                setScrollPosition(containerRef.current.scrollLeft);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await axiosInstance.get<Category[]>(endPoints.GET_CATEGORIES);
                const categoryResponse = response.data;
                setCategories(categoryResponse);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        const fetchOffers = async () => {
            try {
                const response = await axiosInstance.get<Offer[]>(endPoints.GET_OFFERS);
                const offersData = response.data;
                setOffers(offersData);
                setDataLoaded(true);
            } catch (error) {
                console.error("Error fetching offers:", error);
            }
        };

        const fetchUtilityAndGoogleMaps = async () => {
            try {
                const response = await axiosInstance.get<Utility[]>(endPoints.GET_UTILITY_API_KEY);
                const apiKeyData = response.data[0];
                setUtility(apiKeyData);
                const apiKey = apiKeyData.googleMap_key;
                await loadGoogleMaps(apiKey, libraries);
                setGoogleMapsApiKey(apiKey);
                setIsLoaded(true);
            } catch (error) {
                console.error("Error fetching API key or loading Google Maps API:", error);
            }
        };


        const fetchRestaurants = async () => {
            try {
                const response = await axiosInstance.get<Restaurants[]>(endPoints.GET_RESTAURANTS);
                const restaurantData = response.data;
                setRestaurantBranches(restaurantData);
                setRestaurants(restaurantData);
            } catch (error) {
                console.error("Error fetching restaurants:", error);
            }
        };

        handleResize();
        fetchCategories();
        fetchOffers();
        fetchUtilityAndGoogleMaps();
        fetchRestaurants();

        ReactGA.send({hitType: "pageview", page: "/", title: "Home Page"});
        const timeBasedCategory = getTimeCategory();
        setCategory(timeBasedCategory);


        if (containerRef.current) {
            containerRef.current.addEventListener('scroll', handleScroll);
            return () => {
                containerRef.current?.removeEventListener('scroll', handleScroll);
            };
        }
        // handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };


    }, []);


    useEffect(() => {
        if (categories != null) {
            const timeBasedCategory = getTimeCategory();

            const updatedCategories = [...categories];
            console.log("updatedd", updatedCategories)


            const categoryIndex = updatedCategories.findIndex(
                (cat) => cat.categoryName === timeBasedCategory
            );

            if (categoryIndex !== -1) {
                const selectedCategory = updatedCategories.splice(categoryIndex, 1)[0];
                updatedCategories.unshift(selectedCategory);
            }

            console.log("updated", updatedCategories)

            setCategories(updatedCategories);
        }
    }, [category]);


    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    const handleOption = (option: string) => {
        setOption(option);
    };

    const handleCategoryButtons = (category: string) => {
        setCategory(category);
    };

    const getClassName = (optionType: string) => {
        return option === optionType ? "selectedOption" : "option";
    };

    return (
        <>
            <div className="landing-header">
                <nav>
                    <LazyLoadImage
                        className="logo-restoHub"
                        src={Logo}
                        alt="Logo-RestToHub"
                    />
                    {liveLocation !== null && isLoaded ? (
                        <div className="header-input-container">
                            <Autocomplete>
                                <input
                                    type="text"
                                    placeholder="Your Address"
                                    ref={locationRef}
                                    className="header-input"
                                    // onClick={() => searchInputClick("I am going to")}
                                />
                            </Autocomplete>
                        </div>
                    ) : null}

                </nav>
                <div className="banner-image">
                    <div className="discover">
                        Order, Pick Up & Save <br/> on Your Cravings!
                    </div>
                    <div className="hashtag-mobile">
                        <span>#SkipTheLine &nbsp; #RestoHub </span>
                    </div>
                </div>
            </div>
            <>
                <div className="search-option-container">
                    <div className="search-option-buttons">
                        <button
                            className={getClassName("Walk & Pick")}
                            onClick={() => handleOption("Walk & Pick")}
                        >
                            Walk & Pickup
                        </button>
                        <button
                            className={getClassName("Drive & Pick")}
                            onClick={() => handleOption("Drive & Pick")}
                        >
                            Drive & Pickup
                        </button>
                    </div>
                </div>
                {option === "Walk & Pick" ? (
                    isLoaded ? (
                        <Suspense fallback={<RestaurantCarousalSkeleton/>}>
                            <NearByRestaurant/>
                        </Suspense>
                    ) : (
                        <div className="home-restaurant-skeleton-container">
                            <RestaurantCarousalSkeleton/>
                        </div>
                    )
                ) : isLoaded ? (
                    <FindRestaurant/>
                ) : (
                    <SearchBarSkeleton/>
                )}
                <div className="offers">
                    <div className="offers-header">
                        <h1>Offers</h1>
                        <Link className="see-more" to="/offers">See More</Link>
                    </div>
                    <Suspense fallback={<OfferSkeleton/>}>
                        {dataLoaded ? (
                            isMobile ? (
                                <OfferALt/>
                            ) : (
                                <OfferRes/>
                            )
                        ) : (
                            <OfferSkeleton/>
                        )}
                    </Suspense>
                </div>

                <div className="recently-added-container">
                    <h1>Recently Added</h1>
                    <div
                        className={`scroll-arrows left-arrow ${!isScrollable || scrollPosition === 0 ? 'hidden' : ''}`}
                        onClick={() => handleScroll(-250)}
                    >
                        &lt;
                    </div>
                    <div className="recently-added-cards" ref={containerRef}>
                        {isLoaded ? (
                            restaurantBranches
                                .filter((restaurant) => restaurant.isNewRestaurant)
                                .map((restaurant, index) => (
                                    <div key={index}>
                                        <MobileRestaurantCard
                                            branchName={restaurant.branchName}
                                            name={restaurant.name}
                                            place={restaurant.location}
                                            image={restaurant.imagePath}
                                            websiteUrl={restaurant.link}
                                        />
                                    </div>
                                ))
                        ) : (
                            <OfferSkeleton/>
                        )}
                    </div>
                    <div
                        className={`scroll-arrows right-arrow ${!isScrollable || scrollPosition === (containerRef.current?.scrollWidth ?? 0) - (containerRef.current?.clientWidth ?? 0) ? 'hidden' : ''}`}
                        onClick={() => handleScroll(250)}
                    >
                        &gt;
                    </div>
                </div>
                <div className="category-restaurants-container">
                    <div className="category-container">
                        <h1 className="category-heading">Categories</h1>
                        {categories.length > 0 && (
                            <div className="category-buttons">
                                {categories.map((RestaurantCategory, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        className={`category-button ${
                                            category === RestaurantCategory.categoryName ? "selected-category-btn" : ""
                                        }`}
                                        onClick={() => handleCategoryButtons(RestaurantCategory.categoryName)}
                                    >{RestaurantCategory.categoryName}</button>
                                ))}
                            </div>
                        )}
                        {isLoaded ? (
                            <div className="category-restaurants">
                                {restaurantBranches
                                    .filter((restaurant) => restaurant.category.includes(category))
                                    .map((restaurant, index) => (
                                        <div key={index}>
                                            <MobileRestaurantCard
                                                branchName={restaurant.branchName}
                                                name={restaurant.name}
                                                place={restaurant.location}
                                                image={restaurant.imagePath}
                                                websiteUrl={restaurant.link}
                                            />
                                        </div>
                                    ))}
                            </div>
                        ) : (
                            <MobileCardSkeleton/>
                        )}
                    </div>
                </div>
            </>
            {/*)}*/}
            <Footer/>
        </>
    );
}

export default Home;
