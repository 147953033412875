import React from "react";
import Skeleton from "react-loading-skeleton";
import '../../css/Skeleton/SearchBarSkeleton.css'

const SearchBarSkeleton = () => {
    return (
        <>
            {/*<h1 className="location-heading">Find The Restaurants On The Way!</h1>*/}
            <div className="searchBar-container-skeleton">
                <Skeleton width={300} height={55} className="first-child-search-skeleton"/>
                <div className="arrow-double-skeleton">
                    <Skeleton width={30} height={30} className="pc"/>
                </div>
                <Skeleton width={300} height={55} className="second-child-search-skeleton"/>
                <Skeleton width={150} height={50} className="search-btn-skeleton"/>
            </div>
        </>
    );
};

export default SearchBarSkeleton;
