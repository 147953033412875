
export const endPoints ={
    GET_OFFERS:"/offers",
    GET_OFFERS_BY_MERCHANT_ID:"/offers/get-offers",
    GET_OFFER_BY_OFFER_ID:"/offers/get-offer",
    GET_RESTAURANTS:"/restaurants",
    GET_RESTAURANT:"/restaurants/get-restaurant",
    GET_UTILITY_API_KEY:"/key/get-utility",
    ADD_RESTAURANT:"/restaurants/add-restaurant",
    GET_RESTAURANT_IMAGES:"/restohub-api/restaurants/image/",
    GET_CATEGORIES:"/categories/get-category"
} 

