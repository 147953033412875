import React, {useEffect, useMemo, useState} from "react";
import Skeleton from "react-loading-skeleton";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {endPoints} from "../../util/endPoints";
import {CiLocationOn} from "react-icons/ci";

const MobileCardSkeleton = () => {




    const isMobile = window.matchMedia("(max-width: 768px)").matches;


    return (

        <div className="category-restaurants">
            <div className='mobile-restaurant-card-container'>
                <Skeleton className='restaurant-image-recent'/>
                <div className="res-body-recent">
                    <Skeleton width={80} height={10}/>
                    <div className="time-recent" style={{marginTop: "10px"}}>
                        <Skeleton width={20} height={20}/>
                        <Skeleton width={70} height={10} style={{marginLeft: "20px"}}/>
                    </div>
                </div>
            </div>
            <div className='mobile-restaurant-card-container'>
                <Skeleton className='restaurant-image-recent'/>
                <div className="res-body-recent">
                    <Skeleton width={80} height={10}/>
                    <div className="time-recent" style={{marginTop: "10px"}}>
                        <Skeleton width={20} height={20}/>
                        <Skeleton width={70} height={10} style={{marginLeft: "20px"}}/>
                    </div>
                </div>
            </div>
            <div className='mobile-restaurant-card-container'>
                <Skeleton className='restaurant-image-recent'/>
                <div className="res-body-recent">
                    <Skeleton width={80} height={10}/>
                    <div className="time-recent" style={{marginTop: "10px"}}>
                        <Skeleton width={20} height={20}/>
                        <Skeleton width={70} height={10} style={{marginLeft: "20px"}}/>
                    </div>
                </div>
            </div>
            <div className='mobile-restaurant-card-container'>
                <Skeleton className='restaurant-image-recent'/>
                <div className="res-body-recent">
                    <Skeleton width={80} height={10}/>
                    <div className="time-recent" style={{marginTop: "10px"}}>
                        <Skeleton width={20} height={20}/>
                        <Skeleton width={70} height={10} style={{marginLeft: "20px"}}/>
                    </div>
                </div>
            </div>
            <div className='mobile-restaurant-card-container'>
                <Skeleton className='restaurant-image-recent'/>
                <div className="res-body-recent">
                    <Skeleton width={80} height={10}/>
                    <div className="time-recent" style={{marginTop: "10px"}}>
                        <Skeleton width={20} height={20}/>
                        <Skeleton width={70} height={10} style={{marginLeft: "20px"}}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileCardSkeleton;
