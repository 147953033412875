import { ADD_LOCATION } from "../actionTypes/actionTypes";


interface location {
    lat: number | null;
    lng: number | null;
}

const addLocation = (location:location) => {
    return {
        type: ADD_LOCATION,
        payload:location
    };
};

// const deleteItem = () => {
//     return {
//         type: DELETE_ITEM,
//     };
// };

export { addLocation };