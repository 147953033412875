import { createStore, combineReducers } from "redux";
import restaurantReducer from "./restaurantReducer";
import offerReducer from "./offerReducer";
import locationReducer from "./locationReducer";
import locationNameReducer from "./locationNameReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import {Restaurants} from "../interfaces/Restaurant";
import {Offer} from "../interfaces/Offers";


export interface location {
    lat: number | null;
    lng: number | null;
}
export  interface RootState {
    restaurants: Restaurants[];
    offer: Offer[];
    location: location;
    locationName:String
}


const rootReducer = combineReducers({
    offer: offerReducer,
    restaurants: restaurantReducer,
    location:locationReducer,
    locationName:locationNameReducer
});

const store = createStore(rootReducer, composeWithDevTools());

export default store;
