import {useDispatch, useSelector} from "react-redux";
import {Offer} from "../../interfaces/Offers";
import {addOffers} from "../../actions/offerAction";
import {RootState} from "../store";


export function useOffer(): [Offer[], (offers:Offer[]) => void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, Offer[]>(state => state.offer),
        offers => dispatch(addOffers(offers))
    ]
}