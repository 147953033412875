import React, {useEffect, useRef, useState} from "react";
import "../../css/FindRestaurant.css";
import NearRest from "./NearRest";
import {sendGoogleAnalyticsEvent} from "../../tracking/googleAnalytics";
import {events} from "../../tracking/events/events";
import {NearByRestaurants} from "../../interfaces/NearByRestaurants";
import {useLocationName} from "../../reducers/ui/locationNameHoook";
import {useRestaurant} from "../../reducers/ui/restaurantHook";
import {distanceUtils} from "../../util/distanceUtils";
import FindRestaurantViewer from "../Restaurants/FindRestaurantViewer";


function FindRestaurant() {

    let [locationName,] = useLocationName();
    let [restaurants,] = useRestaurant();
    const [map, setMap] = useState<google.maps.Map>();
    const [directionsResponse, setDirectionsResponse] = useState<google.maps.DirectionsResult>();
    const [nearbyRestaurants, setNearbyRestaurants] = useState<NearByRestaurants[]>([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState<NearByRestaurants | null>(null);
    const [distance, setDistance] = useState<string>();
    const [duration, setDuration] = useState<string>();
    const [isPopup, setPopup] = useState<boolean>(false);
    const [displayedMarkers, setDisplayedMarkers] = useState<NearByRestaurants[]>([]);
    const [userLiveLocation, setUserLiveLocation] = useState<google.maps.LatLngLiteral | {}>({});
    const originRef = useRef<HTMLInputElement>(null);
    const destinationRefPopup = useRef<HTMLInputElement>(null);
    const originRefPopup = useRef<HTMLInputElement>(null);
    const destinationRef = useRef<HTMLInputElement>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(true);


    useEffect(() => {
        if (originRef.current && locationName !== null && locationName !== undefined) {
            // @ts-ignore
            originRef.current.value = locationName;
        }
    }, [locationName]);

    useEffect(() => {
        if ("geolocation" in navigator) {
            const geoOptions: PositionOptions = {
                enableHighAccuracy: true,
                maximumAge: 5000,
                timeout: 10000,
            };

            const successCallback = (position: GeolocationPosition) => {
                const userLocation: google.maps.LatLngLiteral = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };

                setUserLiveLocation(userLocation);
                console.log("user Location", userLocation);
            };

            const errorCallback = (error: GeolocationPositionError) => {
                console.error("Error getting user's location:", error);
            };


            navigator.geolocation.getCurrentPosition(
                successCallback,
                errorCallback,
                geoOptions
            );
        } else {
            console.error("Geolocation is not available in this browser.");
        }
    }, []);

    const calculateRoute = async (originRef: React.RefObject<HTMLInputElement>, destinationRef: React.RefObject<HTMLInputElement>) => {
        const originValue = originRef.current?.value || "";
        const destinationValue = destinationRef.current?.value || "";

        console.log("origin value: ", originValue);
        console.log("destination value: ", destinationValue);

        if (originValue === "" || destinationValue === "") {
            return;
        }
        sendGoogleAnalyticsEvent(events.SEARCH, {"iAmHere": originValue, "iAmGoingTo": destinationValue});

        const directionsService = new google.maps.DirectionsService();
        const results = await new Promise<google.maps.DirectionsResult>((resolve) => {
            directionsService.route(
                {
                    origin: originValue,
                    destination: destinationValue,
                    travelMode: google.maps.TravelMode.DRIVING,
                },
                (response, status) => {
                    if (status === "OK") {
                        if (response) {
                            resolve(response);
                        }
                    }
                }
            );
        });

        setDirectionsResponse(results);
        console.log("results:", results);
        if (results.routes[0].legs[0].distance != undefined && results.routes[0].legs[0].duration != undefined) {
            setDistance(results.routes[0].legs[0].distance.text);
            setDuration(results.routes[0].legs[0].duration.text);
        }

        const routeCoordinates = results.routes[0].overview_path;
        const distances = [];

        for (const restaurant of restaurants) {
            const minDistance = calculateDistanceToRoute(
                routeCoordinates,
                restaurant.lat,
                restaurant.lng
            );
            distances.push({...restaurant, distance: minDistance});
        }

        const nearby = distances.filter((restaurant) => restaurant.distance <= distanceUtils.NEARBY_DISTANCE_FROM_TRAVEL_ROUTE);
        setNearbyRestaurants(nearby);
        setDisplayedMarkers(nearby);
        setPopup(true);
    };

    const closePop = () => {
        setDirectionsResponse(undefined);
        setDistance("");
        setDuration("");
        originRef.current!.value = "";
        destinationRef.current!.value = "";
        setNearbyRestaurants([]);
        setDisplayedMarkers([]);
        setSelectedRestaurant(null);
        setPopup(false);
    };

    function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
        const R = 6371;
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) *
            Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    }

    function calculateDistanceToRoute(route: google.maps.LatLng[], lat2: number, lon2: number): number {
        let minDistance = Number.MAX_VALUE;

        for (let i = 0; i < route.length; i++) {
            const lat1 = route[i].lat();
            const lon1 = route[i].lng();
            const distance = calculateDistance(lat1, lon1, lat2, lon2);
            if (distance < minDistance) {
                minDistance = distance;
            }
        }

        return minDistance;
    }

    useEffect(() => {
        if (isPopup) {
            localStorage.setItem('isArrow', 'false');
        } else {
            localStorage.setItem('isArrow', 'true');
        }
    }, [isPopup]);

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <FindRestaurantViewer originRef={originRef} destinationRef={destinationRef}
                              calculateRoute={() => calculateRoute(originRef, destinationRef)} popup={isPopup}
                              originRefPopup={originRefPopup} destinationRefPopup={destinationRefPopup}
                              calculateRoute1={() => calculateRoute(originRefPopup, destinationRefPopup)}
                              closePop={closePop}
                              nearByRestaurants={displayedMarkers}
                              callbackfn={(res, index) => (
                                  <NearRest
                                      key={index}
                                      branchName={res.branchName}
                                      name={res.name}
                                      websiteUrl={res.link}
                                      distance={res.distance}
                                      image={res.imagePath}
                                  />
                              )}
                              directionsResponse={directionsResponse} nearbyRestaurants={nearbyRestaurants}
                              selectedRestaurant={selectedRestaurant} selectedRestaurant1={setSelectedRestaurant}
                              map={setMap} userLiveLocation={userLiveLocation}/>
    );
}

export default FindRestaurant;

