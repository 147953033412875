import './App.css';
 import { Route,Routes } from 'react-router-dom';
 import Home from './components/Home/Home';
 import {SkeletonTheme} from 'react-loading-skeleton'
import ReactGA from "react-ga4";
import {useEffect, useState} from "react";
import {axiosInstance} from "./util/axiosInstance";
import {endPoints} from "./util/endPoints";
import {initializeGoogleAnalytics} from "./tracking/googleAnalytics";
import React from 'react';
import {Utility} from "./interfaces/Utility";
import { Provider } from "react-redux";
import store from "./reducers/store";
import AllOffers from "./components/Offer/AllOffers";
import OfferDetails from "./components/Offer/OfferDetails";


function App() {

    const [gaKey, setGaKey] = useState<string|null>(null);

    localStorage.clear();

    useEffect(() => {
        const fetchGoogleMapsApiKey = async () => {
            try {
                const response = await axiosInstance.get<Utility[]>(endPoints.GET_UTILITY_API_KEY);
                const apiKeyData = response.data[0];
                    const apiKey = apiKeyData.ga_key;
                    setGaKey(apiKey);

            } catch (error) {
                console.error('Error fetching API key:', error);
            }
        };

        // Call the async function
        fetchGoogleMapsApiKey();
    }, []);


    useEffect(() => {
      if (gaKey){
          initializeGoogleAnalytics(gaKey)
      }
    }, [gaKey]);


    return (
        <div className="App">
            <Provider store={store}>
                <div className="App">
                    <SkeletonTheme baseColor="#bdb7b6" highlightColor="#797474">
                        <Routes>
                            <Route path='/' element={<Home/>}/>
                            <Route path='/offers' element={<AllOffers/>}/>
                            <Route path="/offer-details/:merchantId/:offerId" element={<OfferDetails/>}/>
                        </Routes>
                    </SkeletonTheme>
                </div>
            </Provider>
        </div>

    );
}

export default App;
