import axios from 'axios';

const apiPort = process.env.REACT_APP_PORT_KEY;

const baseURL = `${window.location.origin.includes("http://localhost:") ? apiPort : window.location.origin}/restohub-api`
export const axiosInstance = axios.create({
    baseURL
});

console.log("API PORT", apiPort)
console.log("baseURL", baseURL)
