import React, { useEffect, useRef } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { sendGoogleAnalyticsEvent } from "../../tracking/googleAnalytics";
import { events } from "../../tracking/events/events";
import { LiaArrowsAltHSolid ,LiaArrowsAltVSolid} from "react-icons/lia";
import { RxCross1 } from "react-icons/rx";
interface PopupComponentProps {
    originRefPopup: React.RefObject<HTMLInputElement>;
    destinationRefPopup: React.RefObject<HTMLInputElement>;
    calculateRoute: (
        originRef: React.RefObject<HTMLInputElement>,
        destinationRef: React.RefObject<HTMLInputElement>
    ) => void;
    closePop: () => void;
    originVale: string;
    destinationValue: string;
}

const PopupComponent: React.FC<PopupComponentProps> = ({
                                                           originRefPopup,
                                                           destinationRefPopup,
                                                           calculateRoute,
                                                           closePop,
                                                           originVale,
                                                           destinationValue
                                                       }) => {
    const searchInputClick = (index: string) => {
        sendGoogleAnalyticsEvent(events.INPUT_CLICK, { "clicked": `${index} input clicked` });
    };

    const swapInputValues = () => {
        const currentOriginValue = originRefPopup.current!.value;
        originRefPopup.current!.value = destinationRefPopup.current!.value;
        destinationRefPopup.current!.value = currentOriginValue;
    };

    useEffect(() => {
        const originAutocomplete = new window.google.maps.places.Autocomplete(
            originRefPopup.current!,
            {
                componentRestrictions: { country: "LK" },
            }
        );

        const destinationAutocomplete = new window.google.maps.places.Autocomplete(
            destinationRefPopup.current!,
            {
                componentRestrictions: { country: "LK" },
            }
        );
    }, []);

    return (
        <>
            <button className="close-btn" onClick={closePop}>
               X
            </button>
            <div className="popup-searchBar-container">
                <Autocomplete>
                    <input
                        type="text"
                        placeholder="Current Location"
                        ref={originRefPopup}
                        defaultValue={originVale}
                        onClick={() => searchInputClick("I am here")}
                    />
                </Autocomplete>

                <div className="popup-arrows">
                    <LiaArrowsAltHSolid className="pc" size={30} onClick={swapInputValues} />
                    <LiaArrowsAltVSolid className="mobile" size={30} onClick={swapInputValues} />
                </div>

                <Autocomplete>
                    <input
                        type="text"
                        placeholder="Destination"
                        ref={destinationRefPopup}
                        defaultValue={destinationValue}
                        onClick={() => searchInputClick("I am going to")}
                    />
                </Autocomplete>

                <button className="popup-search-btn" type="submit" onClick={() => calculateRoute(originRefPopup, destinationRefPopup)}>
                    Search
                </button>
            </div>
        </>
    );
};

export default PopupComponent;
