import { ADD_LOCATION_NAME } from "../actionTypes/actionTypes";


interface LocationName {
    type: string;
    payload: String;
}

const initialState: String = ""

const locationNameReducer = (state = initialState, action: LocationName): String => {
    switch (action.type) {
        case ADD_LOCATION_NAME:
            return action.payload;
        default:
            return state;
    }
};

export default locationNameReducer;
