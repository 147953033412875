import React, {useEffect, useState} from "react";
import Footer from "../Footer/Footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../css/OfferDetails.css"
import { useParams } from 'react-router-dom';
import {endPoints} from "../../util/endPoints";
import {axiosInstance} from "../../util/axiosInstance";
import {Offer} from "../../interfaces/Offers";
import {constructImageUrl} from "../../util/imageUtility";
import Navigation from "../Navigation/Navigation";
const apiPort = process.env.REACT_APP_PORT_KEY;


function OfferDetailsViewer(props: {
    merchantName: string,
    offer: Offer,
    onClick: () => void,
    offerHTML: string,
    offerDescription: string | undefined,
    offers: Offer[],
    elements: React.JSX.Element[]
}) {
    return <>
        <Navigation merchantName={props.merchantName}/>
        <div className="offer-details-container">
            <div className="offer-detail-container">
                <picture>
                    <source className="offer-detail-image"
                            srcSet={constructImageUrl(apiPort, `${props.offer.imagePath}.webp`)}/>
                    <LazyLoadImage
                        className="offer-detail-image"
                        src={constructImageUrl(apiPort, `${props.offer.imagePath}.jpg`)}
                        alt={`Offer ${props.offer.imagePath}`}/>
                </picture>
                <div className="offer-detail-content">
                    <h2>{props.offer.offerName}</h2>
                    {
                        props.offer.price.includes("%") ?
                            <h3>{props.offer.price} Off</h3> : <h3>Rs.{props.offer.price}</h3>
                    }
                    <button onClick={props.onClick}>Get Offer</button>
                </div>
            </div>
            <div className="offer-detail-description">
                <h3>Product description</h3>
                {props.offerHTML ? <div dangerouslySetInnerHTML={{__html: props.offerHTML}}/> :
                    <p>{props.offerDescription}</p>}
            </div>
            {props.offers.length > 0 && (

                <div className="other-offers-container">
                    <h1>Other Offers</h1>
                    <div className="other-offers">
                        {props.elements}
                    </div>
                </div>
            )}
        </div>
        <Footer/>

    </>;
}

const OfferDetails=()=>{
    const { merchantId, offerId} = useParams();

    const [offers,setOffers]= useState<Offer[]>([])
    const [offer,setOffer]= useState<Offer>();
    const [otherOffers,setOtherOffers]= useState<Offer[]>([]);
    const [merchantName,setMerchantName]= useState<string>("")
    const [offerID,setOfferID]= useState<string|undefined>(offerId)
    const [offerHTML, setOfferHTML] = useState<string>("");
    const [offerDescription, setOfferDescription] = useState<string>();




    useEffect(() => {
        const apiUrl = `${endPoints.GET_OFFERS_BY_MERCHANT_ID}/${merchantId}`;

        axiosInstance
            .get<Offer[]>(apiUrl)
            .then((response) => {
                const offersData = response.data;
                setOffers(offersData);
            })
            .catch((error) => {
                console.error("Error fetching offer:", error);
            });

        const merchantApiUrl = `${endPoints.GET_RESTAURANT}/${merchantId}`;

        axiosInstance
            .get<string>(merchantApiUrl)
            .then((response) => {
                const offersData = response.data;
                setMerchantName(offersData);
            })
            .catch((error) => {
                console.error("Error fetching offer:", error);
            });
    }, [merchantId]);


    useEffect(() => {
        const apiUrl = `${endPoints.GET_OFFER_BY_OFFER_ID}/${offerID}`;
        axiosInstance
            .get<Offer>(apiUrl)
            .then((response) => {
                const offerData = response.data;
                setOffer(offerData);
                console.log("html",offerData.htmlDescription)
                setOfferHTML(offerData.htmlDescription);
                setOfferDescription(offerData.description)
            })
            .catch((error) => {
                console.error("Error fetching offer:", error);
            });
    }, [offerID]);

    useEffect(() => {
        console.log("other-off",offers)

        const otherOffers = offers.filter((o) => o.offerId !== offerID);
        setOtherOffers(otherOffers)
        console.log("other-offers",otherOffers)

    }, [offerID,offers]);

    const handleCardClick = (url:string) => {
        window.open(url.toString(), "_blank");
    };

    const renderOfferCards = (offerArray:Offer[]) => {
        return offerArray.map((offer, index) => (
            <div key={index} className="all-offer-card-container" onClick={() => handleOtherOfferCard(offer.offerId)}>
                <picture>
                    <source className="restaurant-landing-image-all-offer"
                            srcSet={constructImageUrl(apiPort, `${offer.imagePath}.webp`)}/>
                    <LazyLoadImage
                        className="restaurant-landing-image-all-offer"
                        src={constructImageUrl(apiPort, `${offer.imagePath}.jpg`)}
                        alt={`Offer ${offer.imagePath}`}/>
                </picture>
                <div className="all-offer-card-word-body">
                    <p className="get-offer-button">View Offer</p>
                </div>
            </div>
        ));
    };


    const handleOtherOfferCard = (offerID: string) => {
        setOfferID(offerID)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    if (offer) {
        return (
            <OfferDetailsViewer merchantName={merchantName} offer={offer} onClick={() => handleCardClick(offer?.link)}
                                offerHTML={offerHTML} offerDescription={offerDescription} offers={otherOffers}
                                elements={renderOfferCards(otherOffers)}/>
        )
    }
    else {
        return(
            <>
                <Navigation merchantName={merchantName}/>
                <div className="offer-details-container">
                    <div className="offer-detail-container">
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}

export default OfferDetails