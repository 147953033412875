import React, { useEffect, useRef } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { sendGoogleAnalyticsEvent } from "../../tracking/googleAnalytics";
import { events } from "../../tracking/events/events";

interface SearchBarComponentProps {
  originRef: React.RefObject<HTMLInputElement>;
  destinationRef: React.RefObject<HTMLInputElement>;
  calculateRoute: (
    originRef: React.RefObject<HTMLInputElement>,
    destinationRef: React.RefObject<HTMLInputElement>
  ) => void;
}

const SearchBarComponent: React.FC<SearchBarComponentProps> = ({
  originRef,
  destinationRef,
  calculateRoute,
}) => {
  const searchInputClick = (index: string) => {
    sendGoogleAnalyticsEvent(events.INPUT_CLICK, {
      clicked: `${index} input clicked`,
    });
  };

  const swapInputValues = () => {
    const originValue = originRef.current!.value;
    originRef.current!.value = destinationRef.current!.value;
    destinationRef.current!.value = originValue;
  };

  useEffect(() => {
    const originAutocomplete = new window.google.maps.places.Autocomplete(
      originRef.current!,
      {
        componentRestrictions: { country: "LK" },
      }
    );

    const destinationAutocomplete = new window.google.maps.places.Autocomplete(
      destinationRef.current!,
      {
        componentRestrictions: { country: "LK" },
      }
    );
  }, []);
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  return (
    <>

          <div className="search-bar-container">
              <div className="vertical-line-container">
                  <div className="circle circle1"></div>
                  <div className="vertical-line"></div>
                  <div className="circle circle2"></div>
              </div>
              <div className="searchBar-container mobile-search-bar">
                  <Autocomplete>
                      <input
                          type="text"
                          placeholder="Current Location"
                          ref={originRef}
                          className="first-child-search"
                          onClick={() => searchInputClick("I am here")}
                      />
                  </Autocomplete>
                  <Autocomplete>
                      <input
                          type="text"
                          placeholder="Destination"
                          ref={destinationRef}
                          onClick={() => searchInputClick("I am going to")}
                      />
                  </Autocomplete>
                  <button
                      className="search-btn"
                      type="submit"
                      onClick={() => calculateRoute(originRef, destinationRef)}
                  >
                      Search
                  </button>
              </div>
          </div>

    </>
  );
};

export default SearchBarComponent;
