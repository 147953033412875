import React, {useEffect, useState} from "react";
import Navigation from "../Navigation/Navigation";
import "../../css/AllOffers.css"
import {useNavigate} from "react-router-dom";
import {useOffer} from "../../reducers/ui/offerHook";
import {Offer} from "../../interfaces/Offers";
import {endPoints} from "../../util/endPoints";
import {axiosInstance} from "../../util/axiosInstance";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {constructImageUrl} from "../../util/imageUtility";
import Footer from "../Footer/Footer";



const apiPort = process.env.REACT_APP_PORT_KEY;
const AllOffers=()=>{
    const handleCardClick = (url:string) => {
        window.open(url.toString(), "_blank");
    };
    const [showAdditionalRows, setShowAdditionalRows] = useState(false);
    const [offers, setOffers] = useOffer();

    useEffect(() => {
        const apiUrl = endPoints.GET_OFFERS;

        axiosInstance
            .get<Offer[]>(apiUrl)
            .then((response) => {
                const offersData = response.data;
                setOffers(offersData);
            })
            .catch((error) => {
                console.error("Error fetching offer:", error);
            });
    }, []);

    const renderOfferCards = (offerArray:Offer[]) => {
        return offerArray.map((offer, index) => (
            <div key={index} className="all-offer-card-container" onClick={() => handleCardClick(offer.link)}>
                <picture>
                    <source className="restaurant-landing-image-all-offer" srcSet={constructImageUrl(apiPort, `${offer.imagePath}.webp`)}/>
                    <LazyLoadImage
                        className="restaurant-landing-image-all-offer" src={constructImageUrl(apiPort, `${offer.imagePath}.jpg`)}
                        alt={`Offer ${offer.imagePath}`}/>
                </picture>
                <div className="all-offer-card-word-body">
                    <p className="get-offer-button">Get Offer</p>
                </div>
            </div>
        ));
    };


    return (
        <>
            <Navigation/>
            <div className="offer-landing-image">
                <h1>Offers</h1>
            </div>
            <div className="all-offers-container">
                <div className="all-offers-wording-container">
                    <h1>Ongoing Offers</h1>
                    <h3 onClick={()=>setShowAdditionalRows(!showAdditionalRows)}> {showAdditionalRows ? 'View Less' : 'View All'}</h3>
                </div>
                <div className={`all-offer-cards-container ${showAdditionalRows ? 'show-additional-rows' : ''}`}>
                    {renderOfferCards(offers)}
                </div>
            </div>
            <Footer/>

        </>
    )
}
export default AllOffers;