import { ADD_OFFERS } from "../actionTypes/actionTypes";
import {Offer} from "../interfaces/Offers"

interface OfferAction {
    type: string;
    payload: Offer[];
}

const initialState: Offer[] = [];

const offerReducer = (state = initialState, action: OfferAction): Offer[] => {
    switch (action.type) {
        case ADD_OFFERS:
            return action.payload;
        default:
            return state;
    }
};

export default offerReducer;
