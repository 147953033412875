
import {endPoints} from "../util/endPoints";

export const constructImageUrl = (apiPort: string|undefined, image: string) => {
    return `${
        window.location.origin.includes("http://localhost:")
            ? apiPort
            : window.location.origin
    }${endPoints.GET_RESTAURANT_IMAGES}${image}`;
};
