import React from 'react';
import { FaLocationArrow } from 'react-icons/fa';
import { FaArrowRight } from "react-icons/fa";
import '../../css/NearRest.css';
import { sendGoogleAnalyticsEvent } from '../../tracking/googleAnalytics';
import { events } from "../../tracking/events/events";
import {endPoints} from "../../util/endPoints";
import {constructImageUrl} from "../../util/imageUtility";
import {LazyLoadImage} from "react-lazy-load-image-component";

interface NearRestProps {
    name: string;
    branchName:string;
    websiteUrl: string;
    image: string;
    distance: number | undefined;
}

const apiPort = process.env.REACT_APP_PORT_KEY;

const NearRest: React.FC<NearRestProps> = (props:NearRestProps) => {
    const navigate = () => {
        sendGoogleAnalyticsEvent(events.SELECT_ITEM, {
            "item_list_name": "Restaurants",
            "items": [{ "item_name": props.name }]
        });
        window.open(props.websiteUrl, "_blank");
    }

    const formattedDistance = props.distance !== undefined
        ? props.distance < 0.1
            ? props.distance.toFixed(2)
            : props.distance.toFixed(1)
        : "N/A";
    const isMobile = window.matchMedia("(max-width: 768px)").matches;


    return (
        <div className='NearResContainer' onClick={navigate}>
            <picture>
                <source className="nearest-img"
                        srcSet={constructImageUrl(apiPort, `${props.image}.webp`)}/>
                <LazyLoadImage
                    className="nearest-img" src={constructImageUrl(apiPort, `${props.image}.jpg`)}
                    alt={`Offer ${props.image}`}/>
            </picture>
            <div className="res-body">
                <div className="res-word">
                    <h2>
                        {props.branchName !== '' ? (
                            `${props.name} - ${props.branchName}`
                        ) : (
                            props.name
                        )}
                    </h2>
                    <div className="distance-container">
                        <FaLocationArrow/>
                        {!isMobile ?
                            <p>Distance to reach <span style={{"color": "#EC494D"}}>({formattedDistance} Km)</span>
                            </p> :
                            <p><span style={{"color": "#EC494D"}}>({formattedDistance} Km)</span> from route</p>

                        }
                    </div>
                </div>
                {!isMobile && <FaArrowRight size={30}/>}
            </div>
        </div>
    );
}

export default NearRest;
