import React from "react";
import "../../css/Footer.css";
import {
    AiFillFacebook,
    AiFillInstagram,
} from "react-icons/ai";
import  FooterLogo from '../../assets/logos/FooterLogo.webp';
import {sendGoogleAnalyticsEvent} from "../../tracking/googleAnalytics";
import {events} from "../../tracking/events/events";
import {socialLinks} from "../../util/socialLinks";

function Footer() {
    const visitStore = (link:string, socialMedia:string) => {
        sendGoogleAnalyticsEvent(events.SHARE, {"Method": socialMedia})
        // @ts-ignore
        window.open(link, "_blank");
    };
    return (
        <>
            <footer>
                <div className="row-one">
                    <div className="logo-col">
                            <img className='logo-footer' src={FooterLogo} alt="Logo-Footer"/>
                    </div>

                    <div className="social-col">
                        <h2>Follow Us</h2>
                        <div className="social-links">
                            <AiFillFacebook
                                onClick={() =>
                                    visitStore(socialLinks.FACEBOOK, "Facebook"
                                    )
                                }
                            />
                            {/* <AiOutlineTwitter size={30} /> */}
                            <AiFillInstagram
                                onClick={() =>
                                    visitStore(socialLinks.INSTAGRAM, "Instagram")
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="row-two"> &#169; All Rights Reserved</div>
            </footer>
        </>
    );
}

export default Footer;
