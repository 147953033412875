import { ADD_LOCATION_NAME } from "../actionTypes/actionTypes";
const addLocationName = (locationName:String) => {
    return {
        type: ADD_LOCATION_NAME,
        payload:locationName
    };
};

// const deleteItem = () => {
//     return {
//         type: DELETE_ITEM,
//     };
// };

export { addLocationName };