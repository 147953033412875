import { ADD_RESTAURANTS } from "../actionTypes/actionTypes";
import { Restaurants } from "../interfaces/Restaurant";

interface RestaurantAction {
    type: string;
    payload: Restaurants[];
}

const restaurantReducer = (state: Restaurants[] = [], action: RestaurantAction): Restaurants[] => {
    switch (action.type) {
        case ADD_RESTAURANTS:
            return action.payload;
        default:
            return state;
    }
};

export default restaurantReducer;
