import React from "react";
import {NearByRestaurants} from "../../interfaces/NearByRestaurants";
import SearchBarComponent from "../location/SearchBar";
import PopupComponent from "../location/Popup";
import noItem from "../../assets/error-images/popup-no-restaurant.jpg";
import GoogleMapComponent from "../Maps/GoogleMap";


const center: google.maps.LatLngLiteral = { lat: 6.924642351550409, lng: 79.86013980794775 };


function FindRestaurantViewer(props: {
    originRef: React.RefObject<HTMLInputElement>,
    destinationRef: React.RefObject<HTMLInputElement>,
    calculateRoute: () => Promise<void>,
    popup: boolean,
    originRefPopup: React.RefObject<HTMLInputElement>,
    destinationRefPopup: React.RefObject<HTMLInputElement>,
    calculateRoute1: () => Promise<void>,
    closePop: () => void,
    nearByRestaurants: NearByRestaurants[],
    callbackfn: (res:NearByRestaurants, index:number) => React.JSX.Element,
    directionsResponse: google.maps.DirectionsResult | undefined,
    nearbyRestaurants: NearByRestaurants[],
    selectedRestaurant: NearByRestaurants | null,
    selectedRestaurant1: (value: (((prevState: (NearByRestaurants | null)) => (NearByRestaurants | null)) | NearByRestaurants | null)) => void,
    map: (value: (((prevState: (google.maps.Map| undefined)) => (google.maps.Map | undefined)) | google.maps.Map | undefined)) => void,
    userLiveLocation: google.maps.LatLngLiteral | {}
}) {
    return <div className="location-wrapper">
        <SearchBarComponent
            originRef={props.originRef}
            destinationRef={props.destinationRef}
            calculateRoute={props.calculateRoute}
        />

        {props.popup && (
            <div className="popup">
                <div className="popup-content">
                    <PopupComponent
                        originRefPopup={props.originRefPopup}
                        destinationRefPopup={props.destinationRefPopup}
                        calculateRoute={props.calculateRoute1}
                        closePop={props.closePop}
                        originVale={props.originRef.current?.value || ""}
                        destinationValue={props.destinationRef.current?.value || ""}
                    />
                    <div className="map-container">
                        <div className="nearRes-container">
                            {props.nearByRestaurants.length > 0 ? (
                                props.nearByRestaurants.map(props.callbackfn)
                            ) : (
                                <>
                                    <img src={noItem} alt="waiting" className="no-item-image"/>
                                    <p className="message">
                                        No restaurants available in this area yet. Please try another route 😃
                                    </p>
                                </>
                            )}
                        </div>

                        <div className="google-map">
                            <GoogleMapComponent
                                center={center}
                                zoom={15}
                                mapContainerStyle={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "20px",
                                }}
                                directionsResponse={props.directionsResponse}
                                nearbyRestaurants={props.nearbyRestaurants}
                                selectedRestaurant={props.selectedRestaurant}
                                setSelectedRestaurant={props.selectedRestaurant1}
                                setMap={props.map}
                                live={props.userLiveLocation as google.maps.LatLngLiteral}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )}
    </div>;
}

export default  FindRestaurantViewer