let isGoogleMapsLoaded = false;

export function loadGoogleMaps(apiKey: string, libraries: string[]) {
    return new Promise<void>((resolve, reject) => {
        if (isGoogleMapsLoaded) {
            resolve();
            return;
        }

        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries.join(
            ","
        )}&region=LK`;

        script.async = true;
        script.defer = true;

        script.onload = () => {
            isGoogleMapsLoaded = true;
            resolve();
        };

        script.onerror = (error) => {
            reject(error);
        };

        document.head.appendChild(script);
    });
}
