import {useDispatch, useSelector} from "react-redux";
import {addLocation} from "../../actions/locationAction";
import {RootState,location} from "../store";


export function useLocation(): [location, (restaurants:location) => void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, location>(state => state.location),
        location => dispatch(addLocation(location))
    ]
}