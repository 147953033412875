import {useDispatch, useSelector} from "react-redux";
import {Restaurants} from "../../interfaces/Restaurant";
import {addRestaurants} from "../../actions/restaurantAction";
import {RootState} from "../store";


export function useRestaurant(): [Restaurants[], (restaurants:Restaurants[]) => void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, Restaurants[]>(state => state.restaurants),
        restaurants => dispatch(addRestaurants(restaurants))
    ]
}