import React, {useRef} from "react";
import {GoogleMap, Marker, DirectionsRenderer} from "@react-google-maps/api";
import Live from "../../assets/icons/live-mark.gif";
import mapIcon from '../../assets/icons/maplogo.png';
import {sendGoogleAnalyticsEvent} from "../../tracking/googleAnalytics";
import {events} from "../../tracking/events/events";
import {NearByRestaurants} from "../../interfaces/NearByRestaurants";


interface GoogleMapComponentProps {
    center: google.maps.LatLngLiteral;
    zoom: number;
    // @ts-ignore
    mapContainerStyle: google.maps.MapOptions["mapContainerStyle"];
    directionsResponse: google.maps.DirectionsRendererOptions["directions"];
    nearbyRestaurants: Array<NearByRestaurants>;
    selectedRestaurant: NearByRestaurants| null ;
    setSelectedRestaurant: (restaurant: NearByRestaurants) => void;
    setMap: (map: google.maps.Map ) => void;
    live: google.maps.LatLngLiteral | null;
}

interface Icon {
    url: string;
    scaledSize: google.maps.Size;
}

const GoogleMapComponent: React.FC<GoogleMapComponentProps> = ({
                                                                   center,
                                                                   zoom,
                                                                   mapContainerStyle,
                                                                   directionsResponse,
                                                                   nearbyRestaurants,
                                                                   selectedRestaurant,
                                                                   setSelectedRestaurant,
                                                                   setMap,
                                                                   live,
                                                               }) => {
    const mapRef = useRef<google.maps.Map | null>(null);
    const liveIcon:Icon = {
        url: Live,
        scaledSize: new window.google.maps.Size(120, 80),
    };
    const nearbyRestaurantIcon:Icon = {
        url: mapIcon,
        scaledSize: new window.google.maps.Size(30, 35),
    };

    return (
        <GoogleMap
            center={center}
            zoom={zoom}
            mapContainerStyle={mapContainerStyle}
            options={{
                disableDefaultUI: true,
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                styles: [
                    {
                        featureType: "administrative",
                        elementType: "geometry",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                    {
                        featureType: "poi",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                    {
                        featureType: "road.arterial",
                        elementType: "labels",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "labels",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                    {
                        featureType: "road.local",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                    {
                        featureType: "transit",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                ],
            }}
            onLoad={(map) => setMap(map)}
        >
            {live && <Marker position={live} title="Your Location" icon={liveIcon}/>}
            {directionsResponse && (
                <DirectionsRenderer directions={directionsResponse}/>
            )}

            {nearbyRestaurants.length > 0 && (
                <>
                    {nearbyRestaurants.map((restaurant) => (
                        <Marker
                            key={restaurant.name}
                            position={{lat: restaurant.lat, lng: restaurant.lng}}
                            onClick={() => {
                                sendGoogleAnalyticsEvent(events.MAP_CLICK, {
                                    RestaurantName: restaurant.name,
                                });
                                setSelectedRestaurant(restaurant);
                            }}
                            icon={nearbyRestaurantIcon}
                        />
                    ))}
                </>
            )}

            {selectedRestaurant && (
                <div
                    className="inside-popup">
                    <h3>{selectedRestaurant.name}</h3>
                    <p>
                        Distance: {selectedRestaurant.distance.toFixed(2)} km from route
                    </p>
                </div>
            )}
        </GoogleMap>
    );
};

export default GoogleMapComponent;
