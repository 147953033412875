import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React from "react";

const CardSkeleton = () => {
    return (
        <div >
            <div className="left-col">
                <Skeleton circle width={150} height={150}
                          style={{background: "white", position: "absolute", top: "20px", left: "20%"}}/>
            </div>
            <div className="right-col">
                <Skeleton width={150} height={15} style={{position: "absolute", top: "200px", left: "22%"}}/>
            </div>
            <div className="down-col">
                <Skeleton width={50} height={10} style={{position: "absolute", top: "240px", left: "22%"}}/>
            </div>
            <div className="down-col">
                <Skeleton width={50} height={10} style={{position: "absolute", top: "240px", left: "54%"}}/>
            </div>
            <div className="up-col">
                <Skeleton width={50} height={10} style={{position: "absolute", top: "270px", left: "38%"}}/>
            </div>
            <div className="up-col">
                <Skeleton width={150} height={40} style={{position: "absolute", top: "300px", left: "21%"}}/>
            </div>
        </div>
    );
};

export default CardSkeleton;
