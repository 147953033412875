import React, { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../css/Skeleton/CarouselItemSkeleton.css";



const CarouselItemSkeleton = () => {
    const calculateItemsToShow = () => {
        const availableWidth = window.innerWidth;
        const itemWidth = 320;
        return Math.floor(availableWidth / itemWidth);
    };

    const [itemsToShow, setItemsToShow] = useState(calculateItemsToShow());

    useEffect(() => {
        const handleResize = () => {
            setItemsToShow(calculateItemsToShow());
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const responsiveComputer = useMemo(() => {
        return {
            computer: {
                breakpoint: { max: 4000, min: 1024 },
                items: itemsToShow,
            },
        };
    }, [itemsToShow]);

    const responsiveTabletMobile = {
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 2,
        },
    };

    const responsive =
        window.innerWidth >= 1024 ? responsiveComputer : responsiveTabletMobile;

    const isMobile:boolean = window.matchMedia("(max-width: 1028px)").matches;

    // @ts-ignore
    const SkeletonItem = ({ isMobile }) => {
        return (
            <div className="NearResContainer-home-skelton">
                <div className="restaurant-image-skelton">
                    <Skeleton
                        width={isMobile ? 182 : 270}
                        height={isMobile ? 100 : 169}
                        style={{ borderRadius: "20px 20px 0 0",padding:"0" }}
                    />
                </div>
                <div className="res-body-home-skelton">
                    <div className="res-word-home-skelton">
                        <Skeleton width={isMobile ? 90 : 150} height={isMobile ? 10 : 15} />
                        <Skeleton width={isMobile ? 50 : 50} height={isMobile ? 7 : 10} />
                    </div>
                    <div className="distance-container-home-skelton">
                        <Skeleton width={isMobile ? 15 : 30} height={isMobile ? 15 : 30} />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Carousel
            className="skeleton-carousal"
            arrows={false}
            responsive={responsive}
            showDots={false}
            swipeable={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4500}
        >
            {[...Array(5)].map((_, index) => (
                <SkeletonItem key={index} isMobile={isMobile} />
            ))}
        </Carousel>
    );
};

export default CarouselItemSkeleton;
