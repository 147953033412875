import React, {useEffect, useState} from "react";
import { CiLocationOn } from "react-icons/ci";
import "../../css/MobileRestaurantCard.css";
import CardSkeleton from "../skeletonLoaders/CardSkeleton";
import {sendGoogleAnalyticsEvent} from "../../tracking/googleAnalytics";
import {events} from "../../tracking/events/events";
import {endPoints} from "../../util/endPoints";
import {LazyLoadImage} from "react-lazy-load-image-component";
import { constructImageUrl } from '../../util/imageUtility';
import {useLocation} from "react-router-dom";

interface RestaurantProps {
    name: string;
    branchName:string;
    place: string;
    image: string;
    websiteUrl: string;
}

const apiPort = process.env.REACT_APP_PORT_KEY;

function MobileRestaurantCard(props: RestaurantProps) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const utm_source = searchParams.get("utm_source");
    const utm_medium = searchParams.get("utm_medium");
    const utm_term = searchParams.get("utm_term");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);



    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures the effect runs only once


    const visitStore = () => {
        sendGoogleAnalyticsEvent(events.VIEW_ITEMS, {
            items: [
                {
                    item_name: props.name,
                },
            ],
        });

        const url = new URL(props.websiteUrl);

        if (utm_source !== null && utm_source !== undefined) {
            url.searchParams.set("utm_source", utm_source);
        }

        if (utm_medium !== null && utm_medium !== undefined) {
            url.searchParams.set("utm_medium", utm_medium);
        }

        if (utm_term !== null && utm_term !== undefined) {
            url.searchParams.set("utm_term", utm_term);
        }

        window.open(url.toString(), "_blank");
    };


    // Check if the data is available, if not, show the skeleton loader
    if (!props.name ||  !props.place) {
        return <CardSkeleton/>;
    }

    return (
        <div className='mobile-restaurant-card-container' onClick={visitStore}>
            <picture>
                <source className="restaurant-image-recent"
                        srcSet={constructImageUrl(apiPort, `${props.image}.webp`)}/>
                <LazyLoadImage
                    className="restaurant-image-recent" src={constructImageUrl(apiPort, `${props.image}.jpg`)}
                    alt={`Offer ${props.image}`}/>
            </picture>
            <div className="res-body-recent">
                <h2>
                    {props.branchName !== '' ? (
                        `${props.name} - ${props.branchName}`
                    ) : (
                        props.name
                    )}
                </h2>

                <div className="time-recent">
                    <CiLocationOn size={15}/>
                    <p>{props.place}</p>
                </div>
            </div>
        </div>
    )
        ;
}

export default MobileRestaurantCard;
