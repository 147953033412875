import {useDispatch, useSelector} from "react-redux";
import {addLocationName} from "../../actions/locationNameAction";
import {RootState} from "../store";


export function useLocationName(): [String, (restaurants:String) => void] {
    const dispatch = useDispatch();
    return [
        useSelector<RootState, String>(state => state.locationName),
        locationName => dispatch(addLocationName(locationName))
    ]
}