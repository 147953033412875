import ReactGA from "react-ga4";

export function initializeGoogleAnalytics(apiKey: string) {
    if (!!apiKey) {
        console.log("Initiate Google Tag,", apiKey);
        ReactGA.initialize([{ trackingId: apiKey }]);
    }
}


export function sendGoogleAnalyticsEvent<T>(eventName: string, eventData: T) {
    console.log("React GA event", eventName, eventData);
    ReactGA.gtag("event", eventName, eventData);
}
