import { ADD_RESTAURANTS } from "../actionTypes/actionTypes";
import {Restaurants} from "../interfaces/Restaurant";

const addRestaurants = (restaurants:Restaurants[]) => {
    return {
        type: ADD_RESTAURANTS,
        payload: restaurants,
    };
};

// const deleteItem = () => {
//     return {
//         type: DELETE_ITEM,
//     };
// };

export { addRestaurants };