import { ADD_OFFERS } from "../actionTypes/actionTypes";
import {Offer} from "../interfaces/Offers"
const addOffers = (offers:Offer[]) => {
    return {
        type: ADD_OFFERS,
        payload:offers
    };
};

// const deleteItem = () => {
//     return {
//         type: DELETE_ITEM,
//     };
// };

export { addOffers };