import React, {startTransition, useState} from "react";
import NavLogo from "../../assets/logos/Restohub-Logo.webp";
import "../../css/Navigation.css"
import {Link, useNavigate} from "react-router-dom";
import { HiMenuAlt3 } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import {LazyLoadImage} from "react-lazy-load-image-component";

interface propsInterface{
    merchantName?:string
}

const Navigation = (props: propsInterface) => {
    const navigate = useNavigate();
    const [isMenuOpen, setMenuOpen] = useState(false);

    const handleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const handleLogoClick = () => {
        startTransition(() => {
            navigate('/');
        });
    };

    const merchantName = props.merchantName ? `| ${props.merchantName}` : '';

    return (
        <>
            <nav className="offers-nav">
                <div className="nav-logo">
                    <LazyLoadImage src={NavLogo} alt="navigation-logo" onClick={handleLogoClick} />
                    <p>{merchantName}</p>
                </div>
                {isMenuOpen ? (
                    <RxCross2 size={30} onClick={handleMenu} />
                ) : (
                    <HiMenuAlt3 size={30} onClick={handleMenu} />
                )}
            </nav>
            <div className={`mobile-nav ${isMenuOpen ? 'menu-open' : ''}`}>
                {/* Uncomment and style mobile navigation links */}
                <Link className="offer-nav-link-mobile" to="/">
                    Home
                </Link>
                <Link className="offer-nav-link-mobile" to="/offers">
                    Offers
                </Link>
            </div>
        </>
    );
};

export default Navigation;