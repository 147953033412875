import { ADD_LOCATION } from "../actionTypes/actionTypes";

interface location {
    lat: number | null;
    lng: number | null;
}
interface OfferAction {
    type: string;
    payload: location;
}

const initialState: location = {
    lat: null,
    lng: null,
};

const offerReducer = (state = initialState, action: OfferAction): location => {
    switch (action.type) {
        case ADD_LOCATION:
            return action.payload;
        default:
            return state;
    }
};

export default offerReducer;
